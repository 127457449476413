const config = {
  apiGateway: {
    REGION: 'us-east-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://bt9debdfs2.execute-api.us-east-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://gy49yn7qbl.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://6ldpldyjxj.execute-api.us-east-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.staging.rossing.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://ywkefmoqw1.execute-api.us-east-1.amazonaws.com/staging'
  },
};

export default config;